import { constants } from '../utils/constants'
import { messages } from '../utils/messages'
import swal from 'sweetalert2'

export const addressesURL = (branch) => {
  const URL = `https://raw.githubusercontent.com/${constants.organization}/${constants.repoName}/${branch}/${
    constants.addressesSourceFile
  }`
  return URL
}

export const ABIURL = (branch, contract) => {
  const URL = `https://raw.githubusercontent.com/${constants.organization}/${constants.repoName}/${branch}/abis/${
    constants.ABIsSources[contract]
  }`
  return URL
}

export const getABI = (branch, contract) => {
  let addr = ABIURL(branch, contract)
  return fetch(addr).then(response => {
    return response.json()
  })
}

export const wrongRepoAlert = (addr) => {
  swal('Error!', messages.wrongRepo(addr), 'error')
}
